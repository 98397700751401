<template>
  <div>
    <div v-if="!isBusy">
      <div class="d-flex flex-column">
        <div
          class="
            d-flex
            justify-content-md-between
            flex-column flex-md-row
            align-items-center
            border-bottom
            pb-5
          "
        >
          <div class="d-flex mt-1 flex-grow-1">
            <CommonEmployeeSummary
              :item="setEmployeeComponent()"
            ></CommonEmployeeSummary>
          </div>
          <div class="d-flex mt-1 flex-grow-1">
            <EmployeeAttendanceManageDate
              type="full"
              :date="date()"
            ></EmployeeAttendanceManageDate>
          </div>
        </div>
        <div class="d-flex align-items-lg-center flex-column flex-md-row mt-8">
          <div class="d-flex align-items-center flex-lg-fill mr-5 mb-2">
            <span class="mr-4 svg-icon svg-icon-primary"
              ><inline-svg
                src="/media/svg/icons/Navigation/Arrow-up.svg"
              ></inline-svg
            ></span>
            <div class="d-flex flex-column text-dark-75">
              <span class="font-weight-bolder font-size-sm">{{
                $t("DAILYATT.IN")
              }}</span>
              <span class="font-weight-bolder font-size-h5">
                <span
                  class="
                    menu-item
                    bg-light-danger
                    rounded
                    px-1
                    py-1
                    text-danger
                  "
                  v-if="att.late"
                  v-b-tooltip.hover="'Late'"
                >
                  {{ att != null ? att.time_in : " " }}
                </span>
                <span class="" v-if="!att.late">
                  {{ att != null ? att.time_in : " " }}
                </span>
              </span>
            </div>
          </div>
          <div class="d-flex align-items-center flex-lg-fill mr-5 mb-2">
            <span class="mr-4 svg-icon svg-icon-primary"
              ><inline-svg
                src="/media/svg/icons/Navigation/Arrow-down.svg"
              ></inline-svg
            ></span>
            <div class="d-flex flex-column text-dark-75">
              <span class="font-weight-bolder font-size-sm">{{
                $t("DAILYATT.SEEN")
              }}</span
              ><span class="font-weight-bolder font-size-h5">
                {{ att != null ? att.time_out : " " }}
              </span>
            </div>
          </div>
          <div class="d-flex align-items-center flex-lg-fill mr-5 mb-2">
            <span class="mr-4 svg-icon svg-icon-primary">
              <inline-svg
                src="/media/svg/icons/Weather/Temperature-half.svg"
              ></inline-svg>
            </span>
            <div class="d-flex flex-column text-dark-75">
              <span class="font-weight-bolder font-size-sm">{{
                $t("DAILYATT.TEMP")
              }}</span
              ><span class="font-weight-bolder font-size-h5">
                {{
                  att.temperature != null ? att.temperature.temp + " °C" : " "
                }}
              </span>
            </div>
          </div>
          <div class="d-flex align-items-center flex-lg-fill mr-5 mb-2">
            <span class="mr-4 svg-icon svg-icon-primary"
              ><inline-svg
                src="/media/svg/icons/Code/Info-circle.svg"
              ></inline-svg
            ></span>
            <div class="d-flex flex-column text-dark-75">
              <span class="font-weight-bolder font-size-sm">{{
                $t("DAILYATT.STATUS")
              }}</span
              ><span class="font-weight-bolder font-size-h5">
                {{ att.att_status != null ? att.att_status.name : " " }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-skeleton-wrapper v-if="isBusy">
      <b-card>
        <div class="mb-5">
          <b-skeleton width="85%"></b-skeleton>
          <b-skeleton width="55%"></b-skeleton>
          <b-skeleton width="70%"></b-skeleton>
        </div>

        <div class="mb-5">
          <b-skeleton width="85%"></b-skeleton>
          <b-skeleton width="55%"></b-skeleton>
          <b-skeleton width="70%"></b-skeleton>
        </div>
      </b-card>
    </b-skeleton-wrapper>
  </div>
</template>

<script>
import CommonEmployeeSummary from "@/modules/core/components/company/CommonEmployeeSummary";
import EmployeeAttendanceManageDate from "@/modules/company/components/shared/EmployeeAttendanceManageDate";
import { alert } from "sweetalert2";
import { mapGetters } from "vuex";
export default {
  name: "PersonalAttendanceDailyPage",
  components: { EmployeeAttendanceManageDate, CommonEmployeeSummary },
  data() {
    return {
      isBusy: false,
    };
  },
  methods: {
    setEmployeeComponent() {
      return {
        name: this.employee.first_name + " " + this.employee.last_name,
        code: this.employee.unique_code,
        email: this.employee.email,
        image_url: this.employee.image_url,
      };
    },
    date() {
      return new Date();
    },
    fetchEmployee() {
      return this.$store
        .dispatch("getEmployeePersonalProfile")
        .then((response) => {
          console.log("Received", response);
          // this.items = response.data.data;
          return response.data.data;
        })
        .catch(() => {
          alert({
            title: "Failed",
          });
        });
    },
    fetchAttendance() {
      return this.$store
        .dispatch("fetchEmployeePersonalAttendanceDaily")
        .then((response) => {
          console.log("Received", response);
          // this.items = response.data.data;
          return response.data.data;
        })
        .catch(() => {
          alert({
            title: "Failed",
          });
        });
    },
    fetch() {
      this.isBusy = true;
      this.fetchEmployee();
      this.fetchAttendance().finally(() => (this.isBusy = false));
    },
  },
  mounted() {
    this.fetch();
  },
  computed: {
    ...mapGetters({
      employee: "getEmployeePersonalProfile",
      att: "getEmployeePersonalAttendanceDaily",
    }),
  },
};
</script>

<style scoped></style>
